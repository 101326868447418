<style>
.vs__dropdown-menu {
  position: static;
}
</style>
<template>
  <div>
    <b-modal
      id="template-modal"
      size="lg"
      :ok-title="isEditMode ? $t('template.modal.ok-title.edit-mode') : $t('template.modal.ok-title.create-mode')"
      :cancel-title="
        isEditMode ? $t('template.modal.cancel-title.edit-mode') : $t('template.modal.cancel-title.create-mode')
      "
      :title="isEditMode ? $t('template.modal.title.edit-mode') : $t('template.modal.title.create-mode')"
      @show="reset"
      @ok="onOk"
    >
      <template v-slot:default>
        <b-form-group :label="$t('template.modal.body.title')">
          <b-form-input v-model="template.title" :state="template.title != ''"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('template.modal.body.endDate')">
          <b-form-datepicker
            value-as-date
            :value="initialDatepickerValue"
            :locale="$i18n.locale"
            :state="template.endDate != null"
            @input="x => (template.endDate = x)"
          />
        </b-form-group>
        <b-form-group :label="$t('template.modal.body.vehicle-type')">
          <b-select v-model="template.vehicleType">
            <template #first>
              <b-select-option :value="null"></b-select-option>
            </template>
            <b-select-option v-for="(type, i) in vehicleTypes" :key="i" :value="type">
              {{ type.title[$i18n.locale] }}
            </b-select-option>
          </b-select>
        </b-form-group>
        <b-form-group :label="$t('template.modal.body.vehicle-category')">
          <b-select v-model="template.vehicleCategory">
            <template #first>
              <b-select-option :value="null"></b-select-option>
            </template>
            <b-select-option v-for="(category, i) in vehicleCategories" :key="i" :value="category">
              {{ category.title[$i18n.locale] }}
            </b-select-option>
          </b-select>
        </b-form-group>
        <b-form-group :label="$t('template.modal.body.goods-list')">
          <b-button class="my-2" @click="addGoodsTemplate">{{ $t("template.modal.body.add-goods-btn") }}</b-button>
          <b-input v-model="filters.goodsTitle" :placeholder="$t('template.modal.body.filters.goods-title')"></b-input>
          <b-table :fields="fields" :items="template.goodsTemplates" :tbody-tr-class="getRowClass" responsive>
            <template v-slot:cell(thumbnailImage)="row">
              <thumbnail-image :imageId="row.item.goods.imageId" />
            </template>
            <template v-slot:cell(goodsTitle)="data">
              <v-select
                :clearable="false"
                :value="data.item.goods"
                :options="options"
                :getOptionLabel="getOptionLabel"
                @input="x => (template.goodsTemplates[data.index].goods = x)"
              >
                <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
              </v-select>
            </template>
            <template v-slot:cell(minQuantity)="data">
              <b-input
                v-model.trim="data.item.minQuantity"
                :state="checkMinQuantityCell(data.item.minQuantity, data.item.quantity)"
              ></b-input>
            </template>
            <template v-slot:cell(quantity)="data">
              <b-input
                v-model.trim="data.item.quantity"
                :state="checkQuantityCell(data.item.quantity, data.item.minQuantity)"
              ></b-input>
            </template>
            <template v-slot:cell(unit)="data">
              <b-input v-model="data.item.unit" :state="data.item.unit != ''"></b-input>
            </template>
            <template v-slot:cell(delete)="data">
              <b-button variant="outline-danger" @click="onDeleteGoodsTemplate(data.index)">
                {{ $t("template.modal.body.remove-goods-btn") }}
              </b-button>
            </template>
          </b-table>
        </b-form-group>
        <b-form-group :label="$t('template.modal.body.custom-checks')">
          <custom-check-list :customChecks="availableCustomChecks" v-model="template.customChecks"></custom-check-list>
        </b-form-group>
        <div class="my-2" v-show="isEditMode">
          <b-button variant="danger" v-b-modal.remove-template-modal>
            {{ $t("template.modal.body.remove-goods-btn") }}
          </b-button>
          <b-modal
            id="remove-template-modal"
            :title="$t('template.modal.body.remove-modal.title')"
            :ok-disabled="templateTitleForRemove !== template.title"
            :ok-title="$t('template.modal.body.remove-modal.ok-btn')"
            :cancel-title="$t('template.modal.body.remove-modal.cancel-btn')"
            ok-variant="danger"
            @ok="remove"
            @hide="templateTitleForRemove = ''"
          >
            <template v-slot:default>
              <b-form-group :label="$t('template.modal.body.remove-modal.body')">
                <b-form-input v-model="templateTitleForRemove"></b-form-input>
              </b-form-group>
            </template>
          </b-modal>
        </div>
      </template>
    </b-modal>
    <image-modal />
  </div>
</template>

<script>
import CustomCheckList from "./CustomCheckList.vue";

import { RU, EN, LT } from "@/const/langs.js";
import { OPEN_TEMPLATE_MODAL, UPDATE_TEMPLATE_LIST } from "@/const/events.js";

import date from "@/services/date.js";
import converter from "@/services/converter.js";
import ImageModal from "@/components/goods-images/ImageModal.vue";
import ThumbnailImage from "@/components/goods-images/ThumbnailImage.vue";

import { mapActions } from "vuex";
import api from "@/services/api.js";
import stringComparator from "@/services/string-comparator.js";

export default {
  components: {
    CustomCheckList,
    "image-modal": ImageModal,
    "thumbnail-image": ThumbnailImage
  },
  data() {
    return {
      goodsList: [],
      template: this.getDefaultTemplateData(),
      isEditMode: false,
      templateTitleForRemove: "",
      vehicleTypes: [],
      vehicleCategories: [],
      availableCustomChecks: [],
      isFirstSetDefaultTitle: true,
      filters: {
        goodsTitle: ""
      }
    };
  },
  computed: {
    fields() {
      return [
        { key: "thumbnailImage", label: "" },
        { key: "goodsTitle", label: this.$t("template.modal.body.table-field.title") },
        { key: "minQuantity", label: this.$t("template.modal.body.table-field.min-quantity") },
        { key: "quantity", label: this.$t("template.modal.body.table-field.quantity") },
        { key: "unit", label: this.$t("template.modal.body.table-field.unit") },
        { key: "delete", label: this.$t("template.modal.body.table-field.delete-goods-btn") }
      ];
    },
    options() {
      let selectedGoodsId = this.template.goodsTemplates.map(x => x.goods.id);
      return this.goodsList
        .filter(x => !selectedGoodsId.includes(x.id))
        .map(x => {
          return { id: x.id, title: x.title, code: x.code, imageId: x.imageId };
        });
    },
    initialDatepickerValue() {
      return this.template && this.template.endDate ? new Date(this.template.endDate) : new Date();
    },
    validTemplate() {
      return (
        this.template.title !== "" &&
        this.template.endDate != null &&
        (this.template.vehicleType != null || this.template.vehicleCategory != null) &&
        this.template.goodsTemplates.every(
          x =>
            !isNaN(x.goods.id) &&
            this.checkMinQuantityCell(x.minQuantity, x.quantity) &&
            this.checkQuantityCell(x.quantity, x.minQuantity) &&
            x.unit != ""
        ) &&
        this.template.customChecks.every(x => x.id !== null)
      );
    }
  },
  methods: {
    ...mapActions([
      "goods/getBaseInfoList",
      "template/add",
      "template/update",
      "template/remove",
      "vehicleType/getAll",
      "vehicleCategory/getAll"
    ]),
    addGoodsTemplate() {
      this.$set(this.template, "goodsTemplates", [
        ...this.template.goodsTemplates,
        {
          goods: {
            id: NaN,
            title: { [RU]: "", [EN]: "", [LT]: "" }
          },
          minQuantity: "",
          quantity: "",
          unit: this.$t("template.modal.body.unit")
        }
      ]);
    },
    openModal(template) {
      this.isEditMode = template != null;

      if (this.isEditMode == false) {
        template = this.getDefaultTemplateData();
        template.goodsTemplates = this.goodsList
          .filter(x => x.includeInAllTemplates)
          .map(x => {
            return {
              goods: x,
              minQuantity: "",
              quantity: "",
              unit: this.$t("template.modal.body.unit")
            };
          });
      }

      template.goodsTemplates.forEach(x => {
        x.minQuantity = converter.numberToString(x.minQuantity);
        x.quantity = converter.numberToString(x.quantity);
      });

      template.customChecks.sort(function(a, b) {
        return a.order - b.order;
      });
      template.customChecks = template.customChecks.map(x => {
        return this.availableCustomChecks.find(y => y.id === x.customCheckId);
      });

      this.template = template;
      this.$bvModal.show("template-modal");
    },
    reset() {
      this.tempalte = this.getDefaultTemplateData();
      this.templateTitleForRemove = "";
      this.isFirstSetDefaultTitle = true;
      this.filters = {
        goodsTitle: ""
      };
    },
    getDefaultTemplateData() {
      return {
        title: "",
        endDate: new Date(),
        goodsTemplates: [],
        vehicleType: null,
        vehicleCategory: null,
        customChecks: []
      };
    },
    getTemplateVm() {
      return {
        title: this.template.title,
        endDate: date.getStartOfDayToISOString(this.template.endDate),
        goods: this.template.goodsTemplates.map(x => {
          return {
            goodsId: x.goods.id,
            minQuantity: converter.stringToNumber(x.minQuantity),
            quantity: converter.stringToNumber(x.quantity),
            unit: x.unit
          };
        }),
        vehicleType: this.template.vehicleType,
        vehicleCategory: this.template.vehicleCategory,
        customChecks: this.template.customChecks.map((x, i) => {
          if (x.id !== null) {
            return {
              customCheckId: x.id,
              order: i
            };
          }
        })
      };
    },
    onDeleteGoodsTemplate(index) {
      this.template.goodsTemplates = this.template.goodsTemplates.filter((x, i) => i !== index);
    },
    onOk(e) {
      e.preventDefault();
      if (this.validTemplate === false) {
        alert(this.$t("template.modal.wrong-template-data"));
        return;
      }

      let vm = this.getTemplateVm();

      if (this.isEditMode === true) {
        this["template/update"]({
          id: this.template.id,
          template: vm
        })
          .then(() => {
            this.$bvModal.hide("template-modal");
            this.$bus.emit(UPDATE_TEMPLATE_LIST);
          })
          .catch(err => {
            if (err.data.errorCode) {
              alert(this.$t("template.modal.error." + err.data.errorCode));
            } else {
              alert(this.$t("template.modal.error.default-edit-error"));
            }
          });
      } else {
        this["template/add"](vm)
          .then(() => {
            this.$bvModal.hide("template-modal");
            this.$bus.emit(UPDATE_TEMPLATE_LIST);
          })
          .catch(err => {
            if (err.data.errorCode) {
              alert(this.$t("template.modal.error." + err.data.errorCode, err.data.additionalData));
            } else {
              alert(this.$t("template.modal.error.default-add-error"));
            }
          });
      }
    },
    remove() {
      this["template/remove"]({
        id: this.template.id,
        template: this.getTemplateVm()
      })
        .then(() => {
          this.$bvModal.hide("template-modal");
          this.$bus.emit(UPDATE_TEMPLATE_LIST);
        })
        .catch(err => {
          if (err.data.errorCode) {
            alert(this.$t("template.modal.error." + err.data.errorCode));
          } else {
            alert(this.$t("template.modal.error.error-while-deleting-template"));
          }
        });
    },
    getOptionLabel(value) {
      if (isNaN(value.id)) return "";
      return `${value.title[this.$i18n.locale]} - ${value.code}`;
    },
    checkMinQuantityCell(minQuantity, quantity) {
      return converter.stringToNumber(minQuantity) <= converter.stringToNumber(quantity);
    },
    checkQuantityCell(quantity, minQuantity) {
      return converter.stringToNumber(quantity) >= converter.stringToNumber(minQuantity);
    },
    setDefaultTitle() {
      if (
        this.isFirstSetDefaultTitle === true &&
        this.isEditMode === false &&
        (this.template.vehicleType != null || this.template.vehicleCategory != null)
      ) {
        let type = this.template.vehicleType && this.template.vehicleType.title[this.$i18n.locale];
        let category = this.template.vehicleCategory && this.template.vehicleCategory.title[this.$i18n.locale];

        var title = "";

        if (category != null) {
          title += `${category}-`;
        }

        if (type != null) {
          title += `${type}-`;
        }

        title += `${date.format(new Date(), "MMDDYYYY")}`;

        this.template.title = title;
        this.isFirstSetDefaultTitle = false;
      }
    },
    getRowClass(item) {
      if (item && item.goods.id) {
        const goods = this.goodsList.find(g => g.id === item.goods.id);
        const title = this.getOptionLabel(goods);

        if (!stringComparator.ciIncludes(title, this.filters.goodsTitle)) {
          return "d-none";
        }
      }

      return "";
    }
  },
  watch: {
    "template.vehicleType"() {
      this.setDefaultTitle();
    },
    "template.vehicleCategory"() {
      this.setDefaultTitle();
    }
  },
  mounted() {
    this["goods/getBaseInfoList"]().then(resp => (this.goodsList = resp.data));
    this["vehicleCategory/getAll"]().then(resp => {
      this.vehicleCategories = resp.data;
    });
    this["vehicleType/getAll"]().then(resp => {
      this.vehicleTypes = resp.data;
    });
    api.getCustomChecks().then(resp => (this.availableCustomChecks = resp.data));

    this.$bus.on(OPEN_TEMPLATE_MODAL, this.openModal);
  },
  beforeDestroy() {
    this.$bus.off(OPEN_TEMPLATE_MODAL, this.openModal);
  }
};
</script>
