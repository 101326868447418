import { RU, EN, LT } from "@/const/langs.js";

function stringToNumber(source) {
  if (isNumber(source)) {
    let str = source.toString().replace(",", ".");

    return Number(str);
  }

  return NaN;
}

function numberToString(source) {
  return source.toString().replace(".", ",");
}

function isNumber(source) {
  if (!source || source === "") return false;
  return /^\d+(,\d+)?$/.test(source);
}

function stringToStringTranslate(a, locale) {
  let result = {};
  result[RU] = locale === RU ? a : a + "_" + RU;
  result[EN] = locale === EN ? a : a + "_" + EN;
  result[LT] = locale === LT ? a : a + "_" + LT;
  return result;
}

export default {
  stringToNumber,
  numberToString,
  isNumber,
  stringToStringTranslate
};
