<template>
  <div>
    <b-pagination
      :value="pagination.currentPage"
      :total-rows="pagination.totalCount"
      :per-page="pagination.limit"
      @input="onChangeCurrentPage($event, loadTemplates)"
    ></b-pagination>
    <b-table
      caption-top
      no-local-sorting
      :fields="fields"
      :items="items"
      :caption="caption"
      @sort-changed="onSortChanged($event, loadTemplates)"
    >
      <template v-slot:top-row>
        <td>
          <b-input
            v-model.trim="filter.title"
            :placeholder="$t('template.table.field.title')"
            @change="loadTemplates"
          ></b-input>
        </td>
        <td>
          <b-form-datepicker
            v-model="filter.endDate"
            :locale="$i18n.locale"
            :placeholder="$t('template.table.field.endDate')"
            :label-reset-button="$t('datepicker.reset-btn')"
            value-as-date
            reset-button
            @input="loadTemplates"
          ></b-form-datepicker>
        </td>
        <td></td>
      </template>
      <template #cell(endDate)="row">{{ $d(new Date(row.value)) }}</template>
      <template #cell(goodsCount)="row">{{ row.item.goodsTemplates.length }}</template>
      <template #cell(edit)="row">
        <b-button @click="openTemplateModal(row.item)">{{ $t("template.table.edit-btn") }}</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { OPEN_TEMPLATE_MODAL, UPDATE_TEMPLATE_LIST } from "@/const/events.js";

import paginationMixin from "@/mixins/pagination-mixin.js";

export default {
  mixins: [paginationMixin],
  props: {
    caption: { type: String, default: "" },
    actualTemplates: { type: Boolean }
  },
  data() {
    return {
      items: [],
      filter: {
        title: "",
        endDate: null
      }
    };
  },
  computed: {
    fields() {
      return [
        { key: "title", label: this.$t("template.table.field.title"), sortable: true },
        { key: "endDate", label: this.$t("template.table.field.endDate"), sortable: true },
        { key: "goodsCount", label: this.$t("template.table.field.goodsCount"), sortable: true },
        { key: "edit", label: this.$t("template.table.field.edit") }
      ];
    }
  },
  methods: {
    openTemplateModal(item) {
      //create deep copy
      let copyObj = JSON.parse(JSON.stringify(item));
      this.$bus.emit(OPEN_TEMPLATE_MODAL, copyObj);
    },
    loadTemplates() {
      const action = this.actualTemplates ? "template/findActualTemplates" : "template/findExpiredTemplates";
      const params = {
        ...this.paginationParams,
        title: this.filter.title,
        expiredAt: this.filter.endDate ? this.filter.endDate.toISOString() : null
      };
      this.$store.dispatch(action, params).then(resp => {
        this.items = resp.data.items;
        this.pagination.totalCount = resp.data.totalCount;
      });
    }
  },
  mounted() {
    this.loadTemplates();
    this.$bus.on(UPDATE_TEMPLATE_LIST, this.loadTemplates);
  },
  beforeDestroy() {
    this.$bus.off(UPDATE_TEMPLATE_LIST, this.loadTemplates);
  }
};
</script>
