<template>
  <div>
    <div class="my-2">
      <b-button @click="openTemplateModal" class="mr-2 mb-2">{{ $t("template.create-template") }}</b-button>
      <b-button class="mb-2" @click="onToggleExpiredTemplates">
        {{ $t("template.show-expired-template-list") }}
      </b-button>
    </div>
    <template-table :caption="$t('template.table.caption.actual')" actual-templates></template-table>
    <b-collapse v-model="showExpiredTemplates" id="expired-template-list-collapse">
      <template-table v-if="showExpiredTemplates" :caption="$t('template.table.caption.expired')"></template-table>
    </b-collapse>
    <template-modal />
  </div>
</template>

<script>
import TemplateModal from "./TemplateModal.vue";
import TemplateTable from "./TemplateTable.vue";

import { OPEN_TEMPLATE_MODAL } from "@/const/events.js";

export default {
  components: {
    TemplateModal,
    TemplateTable
  },
  data() {
    return {
      showExpiredTemplates: false
    };
  },
  methods: {
    openTemplateModal() {
      this.$bus.emit(OPEN_TEMPLATE_MODAL, null);
    },
    onToggleExpiredTemplates() {
      this.showExpiredTemplates = !this.showExpiredTemplates;
    }
  }
};
</script>
