<template>
  <div>
    <b-table :items="value" :fields="fields">
      <template v-slot:cell(id)> {{ $t("template.modal.custom-checks.check-title-label") }} </template>
      <template v-slot:cell(title)="row">
        <v-select
          :value="row.item"
          :options="availableOptions"
          :getOptionLabel="x => x.title[$i18n.locale]"
          @input="onCheckInput($event, row.index)"
        >
          <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
        </v-select>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button @click="onDeleteCheckItemClick(row.index)">
          {{ $t("template.modal.custom-checks.remove-btn-caption") }}
        </b-button>
      </template>
    </b-table>
    <b-button :disabled="!canAddNewCheck" @click="onAddCustomCheckClick">
      {{ $t("template.modal.custom-checks.add-check-btn-caption") }}
    </b-button>
  </div>
</template>

<script>
import { RU, EN, LT } from "@/const/langs.js";

export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    customChecks: {
      type: Array,
      required: true
    }
  },
  computed: {
    fields() {
      return [
        { key: "id", thStyle: { display: "none" } },
        { key: "title", thStyle: { display: "none" } },
        { key: "actions", thStyle: { display: "none" } }
      ];
    },
    availableOptions() {
      return this.customChecks.filter(x => this.value.filter(y => y.id == x.id).length === 0);
    },
    canAddNewCheck() {
      return this.value.length === 0 || !this.value.find(x => x.id === null);
    }
  },
  methods: {
    getEmptyItem() {
      return { id: null, title: { [LT]: "", [RU]: "", [EN]: "" } };
    },
    onAddCustomCheckClick() {
      let newValue = [...this.value];
      newValue.push(this.getEmptyItem());
      this.$emit("input", newValue);
    },
    onDeleteCheckItemClick(i) {
      let newValue = [...this.value];
      newValue.splice(i, 1);
      this.$emit("input", newValue);
    },
    onCheckInput(evt, i) {
      let newValue = [...this.value];
      if (evt) {
        newValue[i] = evt;
      } else {
        newValue[i] = this.getEmptyItem();
      }
      this.$emit("input", newValue);
    }
  }
};
</script>
